import requests from './request'
// 获取验证码
export const sms = (phone) => requests({ url: `login/sms/${phone}`, method: 'get', })
// 手机登录
export const login = (data) => requests({ url: 'login/phoneLogin', method: "post", data })
// 会员卡登录
export const login1 = (data) => requests({ url: 'login/cardLogin', method: "post", data })
// 学生信息列表
export const getuserlist = (userInfo) => requests({ url: 'student/list' + `?userInfo=${userInfo}`, method: "get", })
// 高考成绩填报
export const studentEdit = (data) => requests({ url: 'student/edit', method: "put", data })
// 省份列表接口
export const province = () => requests({ url: 'province/list', method: 'get' })
// 报考年份接口
export const yearly = () => requests({ url: 'yearly/list?yearState=2', method: 'get' })
// 科目信息接口
export const subject = () => requests({ url: 'subject/list', method: 'get' })
// 艺考方向/专业类别接口
export const artexam = () => requests({ url: 'artexam/tree', method: 'get' })
// 批次接口
export const batchList = (provinceId, yearlyId, firstMajorId, artWardId, subWardId) => requests({ url: '/batch/fillBatch?provinceId=' + `${provinceId}` + '&yearlyId=' + `${yearlyId}` + '&firstMajorId=' + `${firstMajorId}` + '&artWardId=' + `${artWardId}` + '&subWardId=' + `${subWardId}`, method: 'get' })
// 个人偏好查询接口
export const queryByStuIdAndBatId = (studentId, batchId) => requests({ url: '/intention/queryByStuIdAndBatId?studentId=' + `${studentId}` + '&batchId=' + `${batchId}`, method: 'get' })
// 个人偏好专业列表查询接口
export const fillMajor = ({ eduLevelId, artWardId, subWardId }) => requests({ url: '/major/fillMajor?eduLevelId=' + `${eduLevelId}` + '&artWardId=' + `${artWardId}` + '&subWardId=' + `${subWardId}`, method: 'get' })
// 个人偏好保存接口
export const saveMajor = (data) => requests({ url: '/intention/edit', method: 'put', data })
// 个人偏好添加接口
export const addMajor = (data) => requests({ url: '/intention/add', method: 'post', data })
// 志愿生成接口
export const generate = ({ studentId, batchId, schoolCount }) => requests({ url: '/zhiyuan/generate?batchId=' + `${batchId}` + '&studentId=' + `${studentId}` + '&schoolCount=' + `${schoolCount}`, method: 'get' })
// 志愿查询接口
// export const lookgenerate = (key) => requests({ url: `/zhiyuan/queryGenerateInfo?key=${key}`, method: 'get' })
// 查询字典接口/dict/list?dictNbr
export const getdictNbr = (dict) => requests({ url: '/dict/list?dictNbr=' + `${dict}`, method: 'get' })
// 列表上移
export const upList = ({ key, index }) => requests({ url: '/zhiyuan/moveItemUp?key=' + `${key}` + '&index=' + `${index}`, method: 'put' })
// 列表下移
export const downList = ({ key, index }) => requests({ url: '/zhiyuan/moveItemDown?key=' + `${key}` + '&index=' + `${index}`, method: 'put' })
// 列表删除
export const deleteList = ({ key, index }) => requests({ url: '/zhiyuan/remove?key=' + `${key}` + '&index=' + `${index}`, method: 'delete' })
// 获取志愿概率接口
export const queryProbTypeCount = (key) => requests({ url: '/zhiyuan/queryProbTypeCount?key=' + `${key}`, method: 'get' })
// 获取省份ip接口 /province/userProvince
export const userProvince = () => requests({ url: '/province/userProvince', method: 'get' })
// 退出登录
export const logout = () => requests({ url: '/login/logout', method: 'delete' })
// 志愿保存接口
export const save = (data) => requests({ url: '/wishing/initSave', method: "post", data })
// 获取志愿表接口 /wishing/detail/queryByWishing?wishingId= GET
export const detailList = (wishingId) => requests({ url: '/wishing/detail/queryByWishing?wishing=' + `${wishingId}`, method: 'get' })

// 获取志愿明细接口 /wishing/queryById?recordId= GET
export const queryById = (recordId) => requests({ url: '/wishing/queryById?recordId=' + `${recordId}`, method: 'get' })

// 保存志愿接口  /wishing/detail/save PUT
export const saveWishing = (data) => requests({ url: '/wishing/detail/save', method: 'put', data })
// 下载接口 /wishing/download?recordId=  GET
export const download = (recordId) => requests({ url: '/wishing/download?recordId=' + `${recordId}`, method: 'get', responseType: 'blob' })

// 模拟填报志愿查询接口  /zhiyuan/planList  GET
export const queryByStuId = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags, probTypeCode,) => requests({ url: '/zhiyuan/planList?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}&probTypeCode=` + `${probTypeCode}`, method: 'get' })
// 获取概率筛选信息 /zhiyuan/planCount GET
export const queryProbTypeCount1 = (studentId, batchId, scoreLine, artScoreLine, pageNum, pageSize, collegeName, majorWish, provinces, natureType, collegeClass, featureTags) => requests({ url: '/zhiyuan/planCount?studentId=' + `${studentId}&batchId=` + `${batchId}&scoreLine=` + `${scoreLine}&artScoreLine=` + `${artScoreLine}&pageNum=` + `${pageNum}&pageSize=` + `${pageSize}&collegeName=` + `${collegeName}&majorWish=` + `${majorWish}&provinces=` + `${provinces}&natureType=` + `${natureType}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}`, method: 'get' })

// 获取保存志愿id  /wishing/add 
export const add = (data) => requests({ url: '/wishing/add', method: 'post', data })

// 保存志愿记录 /wishing/detail/batchAdd

export const batchAdd = (data) => requests({ url: '/wishing/detail/batchAdd', method: 'post', data })

// 获取志愿表信息l列表 /wishing/queryByStudent？student=

export const queryByStudent = (student) => requests({ url: '/wishing/queryByStudent?student=' + `${student}`, method: 'get' })

// 获取志愿表数量 /wishing/countByStudent？student=
export const countByStudent = (student) => requests({ url: '/wishing/countByStudent?student=' + `${student}`, method: 'get' })

// 学生详情接口 /student/queryById?recordId=  GET
export const queryById1 = (recordId) => requests({ url: '/student/queryById?recordId=' + `${recordId}`, method: 'get' })

// 删除志愿  /wishing/deleteById?recordId= Delete
export const deleteWishing = (data) => requests({ url: '/wishing/deleteById', method: 'delete', data })

// 查询关注数量  /college/CollectqueryCollectCount?userInfo= GET
export const CollectqueryCollectCount = (userInfo) => requests({ url: '/collegeCollect/queryCollectCount?userInfo=' + `${userInfo}`, method: 'get' })
// 查大学列表 /college/page  GET
export const collegeList = (collegeName, eduLevel, natureType, province, collegeClass, featureTags, pageNum) => requests({ url: '/college/page?collegeName=' + `${collegeName}&eduLevel=` + `${eduLevel}&natureType=` + `${natureType}&province=` + `${province}&collegeClass=` + `${collegeClass}&featureTags=` + `${featureTags}&pageNum=` + `${pageNum}`, method: 'get' })
// 院校对比接口 /college/contrast  GET recordId  yearly
export const contrast = (recordId, yearly) => requests({ url: '/college/contrast?recordId=' + `${recordId}&yearly=` + `${yearly}`, method: 'get' })

// 查专业接口 /major/queryAllSubMajor eduLevelId majorName
export const queryMajor = (eduLevelId, majorName) => requests({ url: '/major/queryAllSubMajor?eduLevelId=' + `${eduLevelId}&majorName=` + `${majorName}`, method: 'get' })

// 修改志愿表名字 /wishing/edit PUT
export const updateWishing = (data) => requests({ url: '/wishing/edit', method: 'put', data })
// 大学详情接口 /college/queryById?recordId= GET
export const queryById2 = (recordId) => requests({ url: '/college/queryById?recordId=' + `${recordId}`, method: 'get' })

