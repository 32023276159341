import personalCenter from '@/views/PersonalCenter'
import myVolunteer from '@/views/PersonalCenter/MyVolunteer'
import detail from '@/views/PersonalCenter/Detail/index'
import collegeDetail from '@/views/FindCollege/CollegeDetail/index'

const personalCenterRoutes = [
    {
        path: 'personalCenter',
        name: 'personalCenter',
        component: personalCenter,
        meta: {
            requiresAuth: true,
            title: '个人中心'
        }
        ,
    },
    {
        path: 'myVolunteer',
        name: 'myVolunteer',
        component: myVolunteer,
        meta: {
            requiresAuth: true,
            title: '我的志愿'
        }
        ,
    },
    {
        path: 'detail',
        name: 'detail',
        component: detail,
        meta: {
            requiresAuth: true,
            title: '志愿详情'
        }
        ,
    }, {
        path: 'collegedetail',
        name: 'collegedetail',
        component: collegeDetail,
        meta: {
            requiresAuth: false,
            title: '志愿详情'
        }
    }
]

export default personalCenterRoutes
