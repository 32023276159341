<template>
  <div class="content-container">
    <PageHeader />
    <div class="page-pot">
      <router-view/>
    </div>
    <PageFooter v-if="this.$route.name !== 'noopsychefill' && this.$route.name !== 'detail'" />
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader/index.vue";
import PageFooter from "@/components/PageFooter/index.vue";
export default {
  name: 'Layout',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.content-container {
  // width: 100%;
  background-color: $page-back-main-color;
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-pot{
    width: 100%;
    
    min-height: 10vh;
  }

}
</style>
