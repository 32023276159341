const state = {
    // 登录状态
    // 完善信息状态
}
const mutations = {
}
const actions = {
}
const getters = {
}

export default {
    state,
    mutations,
    actions,
    getters
}