import { login, login1, getuserlist } from '@/api/index'


const state = {
    userlist: {},
    cardLogin: {},
    userLogin: {},
    userId: '',
    userName: '',
    loginState: {
        state: false,
        islogin: false,
    },
}
const mutations = {
    setLoginState(state, loginState) {
        // localStorage.setItem('loginState', loginState)
        state.loginState.state = loginState.state,
            state.loginState.islogin = loginState.islogin
    },
    setUserList(state, username) {
        localStorage.setItem('username', username)
        state.userName = localStorage.getItem('username')
    },
    USERLOGIN(state, userLogin) {
        state.userLogin = userLogin
        state.userId = userLogin.user_id
        localStorage.setItem('userLogin', JSON.stringify(userLogin))
        localStorage.setItem('token', userLogin.access_token)
        localStorage.setItem('userId', userLogin.user_id)
    },
    CORDLOGIN(state, cardLogin) {
        state.cardLogin = cardLogin
        state.userId = cardLogin.user_id
        localStorage.setItem('cardLogin', JSON.stringify(cardLogin))
        localStorage.setItem('token', cardLogin.access_token)
        localStorage.setItem('userId', cardLogin.user_id)
    },
    USERLIST(state, userlist) {
        state.userlist = userlist
    }

}
const actions = {
    async userLogin({ commit }, data) {
        let result = await login(data)
        // console.log('userLogin', result.data);
        if (result.code === 200) {
            commit("USERLOGIN", result.data);
            commit('setLoginState', { state: false, islogin: true })
            return "ok"
        } else {
            return Promise.reject(new Error('faile'))
        }
    },
    async cardLogin({ commit }, data) {
        // console.log('data1', data);

        let result = await login1(data)
        // console.log('cardLogin', result.data);
        if (result.code === 200) {
            commit("CORDLOGIN", result.data);
            commit('setLoginState', { state: false, islogin: true })
        } else {
            return Promise.reject(new Error('faile'))
        }
    },
    async getUserList({ commit }, userInfo) {
        // console.log('userInfo', userInfo);
        let result = await getuserlist(userInfo)
        if (result.code === 200) {
            commit("USERLIST", result.data);
            return result.data
        } else {

        }
    }
}
const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}