<template>
  <div class="content-box">
    <!-- <TopNav/> -->
    <TopSearch/>
    <MainNav/>
  </div>
</template>

<script>
import TopNav from './TopNav'
import TopSearch from './TopSearch'
import MainNav from './MainNav'

export default {
  name: 'PageHeader',
  components:{
    TopNav,
    TopSearch,
    MainNav
  }
}

</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.content-box{
  width: 100%;

}
</style>