import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout/index.vue'
import dynamicRouter from './dynamic-router';
import personalCenter from './personalCenter'
// import personalCenter from '@/views/PersonalCenter'
import myVolunteer from '@/views/PersonalCenter/MyVolunteer'
Vue.use(VueRouter)
console.log()
const routes = [
  {
    path: "/",
    name: 'layout',
    component: Layout,
    redirect: 'home',
    meta:{
      requiresAuth: false,
      title:'根路由'
    },
    children:[
      ...dynamicRouter,
      ...personalCenter

    ]
  },


]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  const token = localStorage.getItem('token')
  // document.title = to.meta.title
  if (to.meta.requiresAuth) {
    // console.log('token', token);
    if (token != null) {
      next()
      // console.log('123');
    } else {
      // 跳转到首页并提示需要登录
      // console.log('456');
      setTimeout(() => {
        next('/home');
      }, 1000);
      Vue.prototype.$message.warning('请先登录');
    }
  } else {
    next()
  }
  next()
})
export default router
