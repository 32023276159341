<template>
  <div class="top-nav-box">
    <div class="top-nav-inner-box">
      <span class="left-number-box">
        <span>全国服务热线：</span><span class="number-box">400-0000-000</span>
      </span>

      <span class="right-text-box">
        <span>官方微信</span> &nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;
        <span>微信小程序</span>&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;
        <span>帮助</span>
      </span>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
}

</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";

.top-nav-box{
  width: 100%;
  height:36px;
  background-color: #E9E9E9;
  box-sizing: border-box;
  padding-left: $page-content-padding-left;
  display: flex;
  justify-content: center;
  align-items: center;

  .top-nav-inner-box{
    width: $page-content-width;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8A8A8A;

    .left-number-box{
      font-size: 16px;

      .number-box{
        color:$page-main-color;
      }
    }

    .right-text-box{
      font-size: 14px;
    }


  }

}
</style>